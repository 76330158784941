import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';

import mediaReducer from '../reducers/media/mediaSlice';
import dataInfoReducer from '../reducers/dataInfo/dataInfoSlice';
import wordpressPagesReducer from '../reducers/pages/wpPagesSlice';

export const store = configureStore({
    reducer: {
        media: mediaReducer,
        dataInfo: dataInfoReducer,
        pages: wordpressPagesReducer
    },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;