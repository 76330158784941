function Footer() {

    const facebook_link = "https://facebook.com/henryvanarem";
    const youtube_link = "https://youtube.com/henrysuomi";
    const instagram_link = "https://instagram.com/henryvanarem";
    const linkedin_link = "https://www.linkedin.com/in/henry-van-arem-31a40510/";

    const websiteVersion = "2.3.0";

    return (
        <div className="row mt-5">
            <div className="col-sm">
                <h3>Socials</h3>
                <ul className="list-unstyled">
                    <a className="text-white" rel="noreferrer" target="_blank" href={facebook_link}><li> <i className="bi bi-facebook p-3"></i>Facebook</li></a>
                    <a className="text-white" rel="noreferrer" target="_blank" href={youtube_link}><li><i className="bi bi-youtube p-3"></i>Youtube</li></a>
                    <a className="text-white" rel="noreferrer" target="_blank" href={instagram_link}><li><i className="bi bi-instagram p-3"></i>Instagram</li></a>
                    <a className="text-white" rel="noreferrer" target="_blank" href={linkedin_link}><li><i className="bi bi-linkedin p-3"></i>LinkedIn</li></a>
                </ul>
                <p>A passionate individual who always thrive to work on end to end products which develop sustainable and technical systems to create impact.</p>
            </div>

            <div className="col-sm">
                <h3>Information</h3>
                <p>Dutchie🌷, Nerd🤡, BBQ chef🥩, Coffee☕, Chocolate🍫, Whisky🥃, Motorsport🏁, Photographer📷, Scandinavia lover 🎅, SpaceX follower🚀</p>

            </div>

            <div className="col-sm">
                <h3>&lt;webapp /&gt;</h3>
                <p>This website (v{websiteVersion}) has been develop by Henry van Arem. Using the latest web standards with the aim for mobile usage and performance.</p>
                <small>Using the following tools/services</small>
                <ul>
                    <li>React</li>
                    <li>Redux</li>
                    <li>Wordpress backend</li>
                    <li>Bootstrap</li>
                </ul>
            </div>

        </div>
    );
}

export default Footer;
