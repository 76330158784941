import { Link } from "react-router-dom";

import Portfolio from './portfolio';

import React from 'react';

import data from './../resources/data.json';

// import { getFrontPage } from "../reducers/pages/wpPagesSlice";
// import { useAppSelector } from '../store/hooks';

function MainPage() {

    const debugBorders = "";//"border border-primary";
    const yearsWorkExperience = new Date().getFullYear() - 2008;


    // const aboutMeObject = useAppSelector(getFrontPage);

    // function createAboutMe() {
    //     return { __html: aboutMeObject.content.rendered };
    // }
    const [showingEmail, setShowingEmail] = React.useState(false);

    let contactEmailAddress = showingEmail ? (
        <div className="btn btn-light mx-2">Email at: <a href="mailto:henry@vanarem.fi">
            henry@vanarem.fi
        </a></div>
    ) : (
        <div className="btn btn-light mx-2" onClick={() => setShowingEmail(true)}>Contact me</div>
    );

    return (
        <div>
            <section className="section-intro-me py-4">
                <div className={"container " + debugBorders}>
                    <div className="row align-items-center">
                        <div className="col-md-8 xs-center mb-xs-20 ">

                            <h1 className="display-1">I'm Henry <span className="text-primary">van Arem</span></h1>
                            <h1 className="display-3">a Creative Nerd</h1>
                        </div>

                    </div>

                </div>

            </section>

            <section className="section-about-me py-4">
                <div className={"container " + debugBorders}>
                    <div className="row align-items-center">
                        <div className="col-md-4">
                            <img src="/images/me-intro.jpg" alt="Henry" className="img-fluid" />
                        </div>

                        <div className="col-md-8 xs-center mb-xs-20">
                            <h2 className="section-title">About <span className="text-primary">Me</span></h2>
                            <p><strong>Professional <span className="color-text">software engineer</span> with {yearsWorkExperience}+ years experience</strong></p>

                            {/* <div dangerouslySetInnerHTML={createAboutMe()}></div> */}
                            <p>I'm a team player,a problem solver and always looking into ways to improve. I like to analyze and solve issues. Work together in a team and make everyone reach their own goals and increase team productivity. <a rel="noreferrer" target="_blank" href="https://www.linkedin.com/in/henry-van-arem-31a40510">See my profile on LinkedIn</a></p>

                            <h2 className="section-title"><span className="text-primary">Contact</span> Me</h2>

                            <div className="about-button-block">
                                {contactEmailAddress}
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <section className=" py-4">
                <div className={"container " + debugBorders}>
                    <div className="row mt-5">
                        <h1 className="text-center">My <span className="text-primary">Photography Portfolio</span></h1>
                        <Portfolio limit={25} ></Portfolio>
                        <p className="text-center">More can be found on my <a href="https://instagram.com/henryvanarem" target="_blank" rel="noreferrer"> Instagram page</a></p>
                    </div>
                </div>
            </section>


            {/* <section className="section-skills py-4">
                <div className={"container " + debugBorders}>
                    <div className="row px-5">
                        <div className="col-sm">
                            <h1 className="text-center">My <span className="text-primary">Skills</span></h1>
                            <p>Skills and expericence combinations can makes a man <span className="color-text">professional</span>. Acquiring new skills is the best way for becoming a professional</p>

                            <div className="row justify-content-center">
                                <div className="col col-xs">
                                    <p>I've worked with the follow technologies/services and development tooling</p>
                                    <div className="flex flex-wrap">
                                        {data.used.map((technologie, index) => (
                                            <button type="button" className="btn btn-info m-2" key={index}> {technologie}</button>
                                        ))}
                                    </div>
                                </div>

                                <div className="col col-xs">
                                    {data.skills.map((skill) =>
                                        <div key={skill.name} className="p-1">
                                            <span>{skill.name}</span>
                                            <div className="progress" style={{ height: "5px" }}>
                                                <div className="progress-bar" role="progressbar" style={{ width: skill.value + "%" }} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100}></div>
                                            </div>
                                        </div>
                                    )}
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section> */}

            <section className="section-experience py-4">
                <div className={"container " + debugBorders}>
                    <div className="row px-5">
                        <div className="col-sm">
                            <h1 className="text-center">My <span className="text-primary">Experience</span></h1>
                            <div className="row justify-content-center">
                                {data.experience.map((exp) =>
                                    <div key={exp.startDate} className={"card col-sm-3 m-4 " + debugBorders}>
                                        <div className="card-body">
                                            <h5 className="card-title">{exp.company}</h5>
                                            <h6 className="card-subtitle mb-2 text-muted">{exp.jobTitle}</h6>
                                            <p className="card-text">{exp.summary}</p>
                                            <p className="float-start fs-6 fst-italic">Start: {exp.startDate}</p>
                                            <p className="float-end fs-6 fst-italic">End: {exp.endDate}</p>
                                            <div className="clearfix"></div>
                                            <a href={exp.links} target="_blank" rel="noreferrer" className="card-link">Visit {exp.company}'s website</a>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div >
    );
}

export default MainPage;
