
import { Link } from "react-router-dom";

import React, { useState } from 'react';


import { useAppSelector } from '../store/hooks';
import { getDataInfo } from '../reducers/dataInfo/dataInfoSlice';

function NavBar() {
    const dataInfoObject = useAppSelector(getDataInfo);
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);
    const handleNavCollapse = () => {
        setIsNavCollapsed(!isNavCollapsed);
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-light" >
            <Link className="navbar-brand" to="/">{dataInfoObject.name}</Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" onClick={handleNavCollapse}
                aria-controls="navbarText" aria-expanded={!isNavCollapsed ? true : false} aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className={isNavCollapsed ? "collapse navbar-collapse" : "navbar-collapse"} id="navbarText">
                <ul className="navbar-nav mr-auto">

                    <li className="nav-item">
                        <Link className="nav-link" to="/" onClick={handleNavCollapse}>Home</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to="/portfolio" onClick={handleNavCollapse}>Portfolio</Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/media">Media</Link>
                    </li> */}
                    <li className="nav-item">
                        <Link className="nav-link" to="/interests" onClick={handleNavCollapse}>Interests</Link>
                    </li>
                    {/* <li className="nav-item">
                        <Link className="nav-link" to="/contact" onClick={handleNavCollapse}>Contact</Link>
                    </li> */}
                </ul>
            </div>
        </nav>
    );
}

export default NavBar;
