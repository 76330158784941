import './App.css';
import './main.scss';
// React stuff
import React from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route } from "react-router-dom";

// Loading Page Components
import NavBar from './components/NavBar';
import Contact from './components/contact';
import Portfolio from './components/portfolio';
import Footer from './components/footer';
import MainPage from './components/mainPage';
import Interests from './components/interests';

// Redux objects
import { useAppDispatch } from './store/hooks';
import { incrementAsync } from './reducers/dataInfo/dataInfoSlice';
import { commandGetMedia } from './reducers/media/mediaSlice';
//import { requestWordpressPages } from './reducers/pages/wpPagesSlice';

import ScrollToTop from './ScrollToTop';

// import "bootstrap/dist/js/bootstrap.min.js";

function App() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(incrementAsync());
    dispatch(commandGetMedia());

    //dispatch(requestWordpressPages());

  }, []);

  return (
    <div>
      {/* <div className="preloader">
        <div className="preloader-inner">
          <div className="preloader-icon">
            <span></span>
            <span></span>
          </div>
        </div>
      </div> */}

      {/* https://preview.themeforest.net/item/lora-creative-vcard-template/full_screen_preview/22369146?ref=blueberry1 */}
      {/* https://www.templatemonsterpreview.com/demo/52039.html?aff=wptemplate */}

      <Router>

        <ScrollToTop></ScrollToTop>
        <div className="container">
          <NavBar></NavBar>
        </div>


        <Route path="/" exact component={MainPage} />


        <div className="section-portfolio">
          <div className="container">

            <div className="row">
              <div className="col-sm">
                <div className="my-5">
                  <Route path="/portfolio" exact component={Portfolio} />
                  {/* <Route path="/contact" exact component={Contact} /> */}
                  {/* <Route path="/media" component={MediaPage} /> */}
                  <Route path="/interests" component={Interests} />

                </div>

              </div>
            </div>
          </div>
        </div>


        {/* <section className="contact-me align-items-center d-flex parallax-section" data-enllax-ratio=".1" >
          <div className="container">
            <div className="row ">
              <div className="col-sm-12">
                <div className="d-md-flex align-items-center xs-center">
                  <h3>Looking for a <strong>web developer</strong>? I am here</h3> <span className="ml-auto d-inline-block mt-xs-20"><a href="#" className="btn btn-tp">Contact me</a></span>
                </div>
              </div>
            </div>
          </div>
        </section> */}

        <section className="section-footer mt-5">
          <div className="container">
            <Footer></Footer>
          </div>
        </section>

      </Router>
    </div>
  );
}

export default App;
