function Interests() {

    return (
        <div>
            <h2>This is a page describes my interests and hobbies in no particular order.</h2>
            <div className="container">
                <div className="row my-5">
                    <div className="col-sm-12 col-md-5 col-lg-4">
                        <h3>Home Automation</h3>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">OpenHab</li>
                            <li className="list-group-item">HomeAssistant</li>
                            <li className="list-group-item">Zigbee</li>
                            <li className="list-group-item">MQTT</li>
                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-8">
                        <p>To control the house i'm using OpenHab and HomeAssistant services. These can control the lights based on logic and sensors throughout the house</p>
                    </div>
                </div>

                <div className="row my-5">
                    <div className="col-sm-12 col-md-5 col-lg-4">
                        <h3>Hardware/Software</h3>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">Raspberry Pi</li>
                            <li className="list-group-item">Jetson Nano</li>
                            <li className="list-group-item">NAS</li>
                            <li className="list-group-item">Docker</li>
                            <li className="list-group-item">React/Wordpress</li>
                            <li className="list-group-item">AI</li>
                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-8">
                        <p>To control the house and website and other i've created several services to communicate between eachother.</p>
                        <p>Taking this website as an example. It's created as an application instead of a website. It loads the app and while communicating with Wordpress blog CMS to retrieve some data and photographs.</p>
                    </div>
                </div>

                <div className="row my-5">
                    <div className="col-sm-12 col-md-5 col-lg-4">
                        <h3>Space</h3>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">NASA</li>
                            <li className="list-group-item">SpaceX</li>
                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-8">
                        <p>Interested in all space related progress. following most Falcon 9 launches and keep up to date with the latest development on StarShip/Heavy Booster in Starbase Texas.</p>
                    </div>
                </div>

                <div className="row my-5">
                    <div className="col-sm-12 col-md-5 col-lg-4">
                        <h3>Photography</h3>
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">Action</li>
                            <li className="list-group-item">Motorsports</li>
                            <li className="list-group-item">Eventing</li>
                        </ul>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-8">
                        <p>I took many photo's of Motorsport and SGW/Eventing events. These can be viewed on my <a href="https://www.facebook.com/VanAremPhotography/" target="_blank" rel="noreferrer">VanAremPhotography</a> Facebook page which has 500+ followers</p>
                    </div>
                </div>
            </div >
        </div >
    );
}

export default Interests;
