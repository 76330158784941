import { selectMedia } from './../reducers/media/mediaSlice';
import { useAppSelector } from './../store/hooks';

function Portfolio({ limit = 25 }) {

    const media = useAppSelector(selectMedia);
    enum ImageSize {
        THUMBNAIL = "thumbnail",
        MEDIUM = "medium",
        LARGE = "large"
    }
    const sizes = 'sizes';
    return (
        <div>
            {media && media[0].media_details && media.map((item: any) => {
                return <img alt="photograph i made" key={item.id} className=" img-thumbnail m-1 " src={item.media_details[sizes][ImageSize.MEDIUM].source_url} />;
            })}

        </div>
    );
}

export default Portfolio;
