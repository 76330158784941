import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { getWPPages } from './wpPagesAPI';

export interface WpPages {
    contact: any;
    about: any;
    frontpage: any;
    status: 'init' | 'loading' | 'failed' | 'loaded';
}

const initialState: WpPages = {
    contact: { id: '0', title: { rendered: '-' } },
    about: { id: '0', title: { rendered: '-' } },
    frontpage: { id: '0', title: { rendered: '-' }, content: { rendered: '-' } },
    status: 'init',
};

export const requestWordpressPages = createAsyncThunk(
    'counter/requestwordpresspages',
    async () => {
        const response = await getWPPages();
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const wpPagesSlice = createSlice({
    name: 'pages',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Just fetching data no altering 
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(requestWordpressPages.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(requestWordpressPages.fulfilled, (state, action) => {
                state.status = 'loaded';
                console.log(action.payload);

                state.about = action.payload.find((test: any) => test.id === 13)
                state.contact = action.payload.find((test: any) => test.id === 14)
                state.frontpage = action.payload.find((test: any) => test.id === 2)
            });
    },
});

// export const { increment, decrement, incrementByAmount } = dataInfoSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getContactPage = (state: RootState) => state.pages.contact;
export const getAboutPage = (state: RootState) => state.pages.about;
export const getFrontPage = (state: RootState) => state.pages.frontpage;
// export const selectStatus = (state: RootState) => state.pages.data.status;

export default wpPagesSlice.reducer;