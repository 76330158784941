import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store/store';
import { fetchDataInformation } from './dataInfoAPI';

export interface DataInformation {
    data: { name: string };
    status: 'init' | 'loading' | 'failed' | 'loaded';
}

const initialState: DataInformation = {
    data: { name: '-' },
    status: 'init',
};

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.
export const incrementAsync = createAsyncThunk(
    'counter/fetchDataInformation',
    async () => {
        const response = await fetchDataInformation();
        // The value we return becomes the `fulfilled` action payload
        return response;
    }
);

export const dataInfoSlice = createSlice({
    name: 'dataInfo',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        // Just fetching data no altering 
    },
    // The `extraReducers` field lets the slice handle actions defined elsewhere,
    // including actions generated by createAsyncThunk or in other slices.
    extraReducers: (builder) => {
        builder
            .addCase(incrementAsync.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(incrementAsync.fulfilled, (state, action) => {
                state.status = 'loaded';
                state.data = action.payload;
            });
    },
});

// export const { increment, decrement, incrementByAmount } = dataInfoSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const getDataInfo = (state: RootState) => state.dataInfo.data;
export const selectStatus = (state: RootState) => state.dataInfo.status;

export default dataInfoSlice.reducer;