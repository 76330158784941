// A mock function to mimic making an async request for data
export function fetchCount(amount = 1) {
    return new Promise<{ data: number }>((resolve) =>
        setTimeout(() => resolve({ data: amount }), 500)
    );
}

export function getMedia() {
    return fetch('https://www.vanarem.fi/wordpress/wp-json/wp/v2/media?_fields=id,title,media_details')
        .then(response => response.json())
        .then(data => {
            return data
        });
}